import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { MaterialModule } from '..//material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component'

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, RouterModule, NgbModule],
  exports: [CommonModule, FormsModule, MaterialModule, RouterModule, NgbModule, NavbarComponent],
  declarations: [NavbarComponent]
})
export class SharedModule {}
