import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { ArtistService } from '../artist.service'
import { Artist } from '../artist'
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-artist-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.css']
})
export class ArtistListComponent implements OnInit {
  posts: Observable<Artist[]>
  constructor(private postService: ArtistService, public auth: AuthService) {}

  ngOnInit() {
    this.posts = this.postService.getPosts()
  }

  delete(id: string) {
    this.postService.delete(id)
  }

}
