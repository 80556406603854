import { Injectable } from '@angular/core'
import 'rxjs/add/operator/map';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from 'angularfire2/firestore'
import { Literature } from './literature'

@Injectable()
export class LiteratureService {
  postsCollection: AngularFirestoreCollection<Literature>
  postDoc: AngularFirestoreDocument<Literature>

  constructor(private afs: AngularFirestore) {
    this.postsCollection = this.afs.collection('literature', ref =>
      ref.orderBy('published', 'desc')
    )
  }

  getPosts(language = 'Latest') {
    if (language === 'Latest') {
      this.postsCollection = this.afs.collection('literature', ref => ref.orderBy('published', 'desc'));
      return this.postsCollection.snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Literature
          const id = a.payload.doc.id
          return { id, ...data }
        })
      })
    } else {
      this.postsCollection = this.afs.collection("literature", ref => ref.where('language', '==', language));
      return this.postsCollection.snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data()
          const id = a.payload.doc.id
          return { id, ...data }
        })
      })
    }
  }

  getPostData(id: string) {
    this.postDoc = this.afs.doc<Literature>(`literature/${id}`)
    return this.postDoc.valueChanges()
  }

  getPost(id: string) {
    return this.afs.doc<Literature>(`literature/${id}`)
  }

  create(data: Literature) {
    this.postsCollection.add(data)
  }

  delete(id: string) {
    return this.getPost(id).delete()
  }

  update(id: string, formData) {
    return this.getPost(id).update(formData)
  }
}
