import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators'; 
import { AngularFireStorage } from 'angularfire2/storage';
import * as firebase from 'firebase/app';

import { PostService } from '../post.service'
import { Post } from '../post'
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.css']
})
export class PostDetailComponent implements OnInit {
  post: Post
  editing: boolean = false;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  language: string = '';
  isAdmin: boolean = false;
  path: string = 'posts';
  updateAdminPost: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private postService: PostService,
    public auth: AuthService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        this.auth.getUserData(this.auth.currentUserId).subscribe( data => {
          this.isAdmin = data.isAdmin ? true : false;
          this.path = data.isAdmin ? 'user-posts' : 'posts';
          this.getPost();
        });
      }
    });
  }

  getPost(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.postService.getPostData(id, this.path).subscribe(post => {
      this.post = post;
      if(post) {
        this.language = this.post.language;
      } else {
        this.postService.getPostData(id, 'posts').subscribe(post => {
          this.post = post;
          if(post) {
            this.updateAdminPost = true;
            this.language = this.post.language;
          }
        })
      }
    })
  }

  updatePost() {
    const id = this.route.snapshot.paramMap.get('id');
    const formData = {
      title: this.post.title,
      content: this.post.content,
      image: this.post.image,
      language: this.language,
      published: new Date(),
      author: this.post.author,
      authorId: this.post.authorId,
      adminId: this.auth.currentUserId,
    }
    if(this.updateAdminPost) {
      this.postService.update(id, formData, 'posts');
      this.updateAdminPost = false;
    } else {
      if(this.isAdmin) {
        this.postService.create(formData,'posts');
        this.postService.delete(id, 'user-posts')
        this.router.navigate(['/artist-dashboard'])
      }
      if(!this.isAdmin) {
        this.postService.create(formData,'user-posts');
        this.postService.delete(id, 'posts')
        this.router.navigate(['/blog'])
      }
    }
    this.editing = false;
  }

  delete() {
    const id = this.route.snapshot.paramMap.get('id');
    if(this.updateAdminPost) {
      this.postService.delete(id, 'posts')
      this.router.navigate(['/blog']);
    } else {
      if(this.isAdmin) {
        this.postService.delete(id, 'user-posts')
        this.router.navigate(['/artist-dashboard'])
      }
      if(!this.isAdmin) {
        this.postService.delete(id, 'posts')
        this.router.navigate(['/blog'])
      }
    }
  }

  uploadImage(event) {
    const file = event.target.files[0]
    const path = `posts/${file.name}`
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.post.image = url));
        })
      )
      .subscribe();
    }
  }
}
