import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable'
import { AngularFireStorage } from 'angularfire2/storage'
import * as firebase from 'firebase/app';

import { AuthService } from '../../core/auth.service'
import { PostService } from '../post.service'

import { finalize } from 'rxjs/operators'; 

@Component({
  selector: 'app-post-dashboard',
  templateUrl: './post-dashboard.component.html',
  styleUrls: ['./post-dashboard.component.css']
})
export class PostDashboardComponent implements OnInit {
  content: string
  image: string
  title: string
  language: string = 'English';
  isAdmin: boolean = false;
  path: string = 'posts';

  saving = 'Create Post'

  uploadPercent: Observable<number>
  downloadURL: Observable<string>

  constructor(
    private router: Router,
    private auth: AuthService,
    private postService: PostService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      console.log(user);
      if(user) {
        this.auth.getUserData(this.auth.currentUserId).subscribe( data => { 
          if (data) {
            this.isAdmin = data.isAdmin ? true : false;
            this.path = data.isAdmin ? 'posts' : 'user-posts';
          } else {
            this.isAdmin = false;
            this.path = 'user-posts';
          }
        });
      }
    });
  }

  createPost() {
    const postData = {
      author: this.auth.authState.displayName || this.auth.authState.email,
      authorId: this.auth.currentUserId,
      content: this.content,
      image: this.image || null,
      published: new Date(),
      title: this.title,
      language: this.language
    }
    this.postService.create(postData,this.path)
    this.title = ''
    this.content = ''
    this.image = ''

    this.saving = 'Post Created!'
    setTimeout(() => (this.saving = 'Create Post'), 3000)
    this.router.navigate(['/blog'])
  }

  uploadImage(event) {
    const file = event.target.files[0]
    const path = `posts/${file.name}`
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      // const task = this.storage.upload(path, file)
      // this.downloadURL = task.downloadURL()
      // this.uploadPercent = task.percentageChanges()
      // console.log('Image Uploaded!')
      // this.downloadURL.subscribe(url => (this.image = url))
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }
}
