import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable'
import { AngularFireStorage } from 'angularfire2/storage'

import { AuthService } from '../../core/auth.service'
import { LiteratureService } from '../literature.service'

import { finalize } from 'rxjs/operators'; 

@Component({
  selector: 'app-literature-dashboard',
  templateUrl: './literature-dashboard.component.html',
  styleUrls: ['./literature-dashboard.component.css']
})
export class LiteratureDashboardComponent implements OnInit {
  content: string;
  image: string;
  title: string;
  category: string = 'Article';
  subCategory: string[] = [];
  language: string = 'English';

  public articleSubCategories = [
    {name:'Experience', value:'Experience', checked:false},
    {name:'Spiritual', value:'Spiritual', checked:false},
    {name:'Historical', value:'Historical', checked:false},
    {name:'Biography / Autobiography', value:'Biography / Autobiography', checked:false},
    {name:'Cooking', value:'Cooking', checked:false},
    {name:'Travel', value:'Travel', checked:false},
    {name:'Love', value:'Love', checked:false},
    {name:'Information', value:'Information', checked:false},
    {name:'Conceptual', value:'Conceptual', checked:false},
    {name:'Review', value:'Review', checked:false},
    {name:'Social', value:'Social', checked:false},
    {name:'Self Development', value:'Self Development', checked:false},
    {name:'Comedy', value:'Comedy', checked:false},
  ]

  public storySubCategories = [
    {name:'Experience', value:'Experience', checked:false},
    {name:'Historical', value:'Historical', checked:false},
    {name:'Novel', value:'Novel', checked:false},
    {name:'Drama', value:'Drama', checked:false},
    {name:'Travel', value:'Travel', checked:false},
    {name:'Love', value:'Love', checked:false},
    {name:'Inspirational', value:'Inspirational', checked:false},
    {name:'Childhood', value:'Childhood', checked:false},
    {name:'Fear', value:'Fear', checked:false},
    {name:'Mystery / Exciting', value:'Mystery / Exciting', checked:false},
    {name:'Short Stories', value:'Short Stories', checked:false},
    {name:'Social', value:'Social', checked:false},
    {name:'Comedy', value:'Comedy', checked:false},
  ]

  public poemSubCategories = [
    {name:'Abhang', value:'Abhang', checked:false},
    {name:'Spiritual', value:'Spiritual', checked:false},
    {name:'Poetry Collection', value:'Poetry Collection', checked:false},
    {name:'Ghazal', value:'Ghazal', checked:false},
    {name:'Charoli', value:'Charoli', checked:false},
    {name:'Life', value:'Life', checked:false},
    {name:'Love', value:'Love', checked:false},
    {name:'Muktshand', value:'Muktshand', checked:false},
    {name:'Comedy', value:'Comedy', checked:false},
    {name:'Shayari', value:'Shayari', checked:false},
    {name:'Social', value:'Social', checked:false},
    {name:'Feminine', value:'Feminine', checked:false},
  ]

  public subCategoryList = this.articleSubCategories;

  saving = 'Create Literature'

  uploadPercent: Observable<number>
  downloadURL: Observable<string> 

  constructor(
    private router: Router,
    private auth: AuthService,
    private literatureService: LiteratureService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {}

  createPost() {
    this.subCategory = this.subCategoryList
      .filter(opt => opt.checked)
      .map(opt => opt.value)

    const postData = {
      author: this.auth.authState.displayName || this.auth.authState.email,
      authorId: this.auth.currentUserId,
      content: this.content,
      image: this.image || null,
      published: new Date(),
      title: this.title,
      category: this.category,
      subCategory: this.subCategory,
      language: this.language
    }

    this.literatureService.create(postData)
    this.title = ''
    this.content = ''
    this.image = ''

    this.saving = 'Literature Created!'
    setTimeout(() => (this.saving = 'Create Literature'), 3000);
    this.router.navigate(['/literature'])
  }

  selectCategory(category) {
    this.category = category;
    if (category === 'Article') {
      this.subCategoryList = this.articleSubCategories;
    } else if (category === 'Story') {
      this.subCategoryList = this.storySubCategories;
    } else if (category === 'Poem') {
      this.subCategoryList = this.poemSubCategories;
    }
    this.subCategoryList.forEach(item => item.checked = false);
  }

  uploadImage(event) {
    const file = event.target.files[0]
    const path = `literature/${file.name}`
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }
}
