import { Injectable } from '@angular/core'
import { AngularFireAuth } from 'angularfire2/auth'
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from 'angularfire2/firestore'
import * as firebase from 'firebase/app'

@Injectable()
export class AuthService {
  authState: any = null
  userDoc: AngularFirestoreDocument

  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.authState.subscribe(data => this.authState = data)
  }

  get authenticated(): boolean {
    return this.authState !== null
  }

  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : null
  }

  getUserData(id: string) {
    this.userDoc = this.afs.doc(`users/${id}`)
    return this.userDoc.valueChanges()
  }

  login() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }
  logout() {
    this.afAuth.auth.signOut()
  }
}
