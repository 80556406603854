import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { SharedModule } from '../shared/shared.module'

import { LiteratureDashboardComponent } from './literature-dashboard/literature-dashboard.component'
import { LiteratureDetailComponent } from './literature-detail/literature-detail.component'
import { LiteratureListComponent } from './literature-list/literature-list.component'
import { LiteratureService } from './literature.service'

const routes: Routes = [
  { path: 'literature', component: LiteratureListComponent },
  { path: 'literature/:id', component: LiteratureDetailComponent },
  { path: 'literature-dashboard', component: LiteratureDashboardComponent },
]

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    LiteratureDashboardComponent,
    LiteratureDetailComponent,
    LiteratureListComponent
  ],
  providers: [LiteratureService]
})
export class LiteratureModule {}
