import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { LiteratureService } from '../literature.service'
import { Literature } from '../literature'
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-literature-list',
  templateUrl: './literature-list.component.html',
  styleUrls: ['./literature-list.component.css']
})
export class LiteratureListComponent implements OnInit {
  posts: Observable<Literature[]>
  selectedIndex: number = 0;
  languages = [
    'Latest',
    'English',
    'Hindi',
    'Marathi',
    'Konkani'
  ]
  constructor(private literatureService: LiteratureService, public auth: AuthService) {}

  ngOnInit() {
    this.posts = this.literatureService.getPosts();
  }

  selectCard(index: number) {
    this.selectedIndex = index;
    this.posts = this.literatureService.getPosts(this.languages[this.selectedIndex]);
  }

  delete(id: string) {
    this.literatureService.delete(id)
  }

}
