import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable'
import { AngularFireStorage } from 'angularfire2/storage'

import { PostService } from '../../posts/post.service'
import { AuthService } from '../../core/auth.service'
import { Artist } from '../artist';
import * as firebase from 'firebase/app';
import { ArtistService } from '../artist.service'

import { finalize } from 'rxjs/operators'; 

@Component({
  selector: 'app-artist-dashboard',
  templateUrl: './artist-dashboard.component.html',
  styleUrls: ['./artist-dashboard.component.css']
})
export class ArtistDashboardComponent implements OnInit {
  editing: boolean = false;
  content: string
  image: string
  title: string
  public category: string = 'onStage';
  public subCategory: string = '';
  posts;
  isAdmin: boolean = false;
  path: string = 'posts';

  emptyArtistData = {
    name: '',
    image: '',
    category: '',
    subCategory: '',
    performancePlace: '',
    age: '',
    rate: ''
  }

  public onStageSubCategories = [
    'Acting',
    'Dance',
    'Speaker',
    'Host',
    'Standup Comedian',
    'Drama Group',
  ]

  public offStageSubCategories = [
    'Rangoli',
    'Mehandi',
    'Artist',
    'Background Music',
    'Makeup Artist',
  ]

  public bothSubCategories = [
    'Singing',
    'Writer',
    'Instrumentalist',
    'Photographer',
  ]

  public subCategoryList = this.onStageSubCategories;
  public post: any = this.emptyArtistData;

  saving = 'Save'

  uploadPercent: Observable<number>
  downloadURL: Observable<string>

  constructor(
    private postService: PostService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private artistService: ArtistService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        this.auth.getUserData(this.auth.currentUserId).subscribe( data => {
          this.isAdmin = data.isAdmin ? true : false;
          this.path = data.isAdmin ? 'user-posts' : 'posts';
          this.posts = this.postService.getPosts(this.path)
          setTimeout(() => this.getArtist(), 5000);
        });
      }
    });
  }

  getArtist(): void {
    const id = this.auth.currentUserId;
    this.artistService.getArtistData(id).subscribe(post => {
      if (post && post[0]) {
        this.post = post[0];
        this.category = this.post.category;
        this.subCategory = this.post.subCategory;
        this.selectCategory(this.category);
      }
    })
  }

  createPost() {
    const postData = {
      author: this.auth.authState.displayName || this.auth.authState.email,
      authorId: this.auth.currentUserId,
      content: this.content,
      image: this.image || null,
      published: new Date(),
      title: this.title,
      category: this.category,
      subCategory: this.subCategory,
    }
    this.artistService.create(postData)
    this.title = ''
    this.content = ''
    this.image = ''

    this.saving = 'Saved Successfully!'
    setTimeout(() => (this.saving = 'Save'), 3000)
    this.router.navigate(['/literature'])
  }
  
  updateProfile() {
    this.artistService.getArtistData(this.auth.currentUserId).subscribe(post => {
      console.log(this.auth.currentUserId);
      const formData = {
        userId: this.auth.currentUserId,
        userEmail: this.auth.authState.email,
        name: this.post.name,
        image: this.post.image || null,
        category: this.category,
        subCategory: this.subCategory,
        performancePlace: this.post.performancePlace,
        age: this.post.age,
        rate: this.post.rate,
        published: new Date(),
      }
      if (post && post[0]) {
        this.artistService.update(post[0].id, formData)
        // this.editing = false;
      } else {
        this.artistService.create(formData);
      }
    });
  }

  selectCategory(category) {
    this.category = category;
    if (category === 'OnStage') {
      this.subCategoryList = this.onStageSubCategories;
    } else if (category === 'OffStage') {
      this.subCategoryList = this.offStageSubCategories;
    } else if (category === 'Both') {
      this.subCategoryList = this.bothSubCategories;
    }
  }

  uploadImage(event) {
    const file = event.target.files[0]
    const path = `artists/${file.name}`
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.post.image = url));
        })
      )
      .subscribe();
    }
  }
}
