import { Component, OnInit , Renderer2} from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isAdmin: boolean = false;
  public isMenuCollapsed = true;
  constructor(public auth: AuthService, public renderer: Renderer2) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        this.auth.getUserData(this.auth.currentUserId).subscribe( data => {
          this.isAdmin = data.isAdmin ? true : false;
        });
      }
    });
  }
}
