import { Injectable } from '@angular/core'
import 'rxjs/add/operator/map';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from 'angularfire2/firestore'
import { Post } from './post'

@Injectable()
export class PostService {
  postsCollection: AngularFirestoreCollection<Post>
  postsUserCollection: AngularFirestoreCollection<Post>
  postDoc: AngularFirestoreDocument<Post>

  constructor(private afs: AngularFirestore) {
    this.postsCollection = this.afs.collection('posts', ref =>
      ref.orderBy('published', 'desc')
    )
    this.postsUserCollection = this.afs.collection('user-posts', ref =>
      ref.orderBy('published', 'desc')
    )
  }

  getPosts(path = 'posts',language = 'Latest') {
    let collection = this.postsCollection;
    if ( path == 'user-posts' ) {
      collection = this.postsUserCollection;
      return collection.snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Post
          const id = a.payload.doc.id
          return { id, ...data }
        })
      })
    }
    if (language === 'Latest') {
      this.postsCollection = this.afs.collection(path, ref => ref.orderBy('published', 'desc'));
      return this.postsCollection.snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Post
          const id = a.payload.doc.id
          return { id, ...data }
        })
      })
    } else {
      this.postsCollection = this.afs.collection(path, ref => ref.where('language', '==', language));
      return this.postsCollection.snapshotChanges().map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Post
          const id = a.payload.doc.id
          return { id, ...data }
        })
      })
    }
  }

  getPostData(id: string, path = 'posts') {
    this.postDoc = this.afs.doc<Post>(`${path}/${id}`)
    return this.postDoc.valueChanges()
  }

  getPost(id: string, path = 'posts') {
    return this.afs.doc<Post>(`${path}/${id}`)
  }

  create(data: Post, path = 'posts') {
    if ( path == 'user-posts' ) {
      this.postsUserCollection = this.afs.collection('user-posts', ref => ref.orderBy('published', 'desc'));
      this.postsUserCollection.add(data);
    } else {
      this.postsCollection = this.afs.collection('posts', ref => ref.orderBy('published', 'desc'));
      this.postsCollection.add(data);
    }
  }

  delete(id: string, path = 'posts') {
    return this.getPost(id, path).delete()
  }

  update(id: string, formData, path = 'posts') {
    return this.getPost(id,path).update(formData)
  }
}
