import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators'; 
import { AngularFireStorage } from 'angularfire2/storage'

import { ArtistService } from '../artist.service'
import { Artist } from '../artist'
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-artist-detail',
  templateUrl: './artist-detail.component.html',
  styleUrls: ['./artist-detail.component.css']
})
export class ArtistDetailComponent implements OnInit {
  post: Artist
  editing: boolean = false;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private artistService: ArtistService,
    public auth: AuthService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.getPost()
  }

  getPost(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.artistService.getArtistDataByDoc(id).subscribe(post => (this.post = post))
  }

  updatePost() {
    const formData = {
      title: this.post.title,
      content: this.post.content,
      image: this.post.image,
    }
    const id = this.route.snapshot.paramMap.get('id')
    this.artistService.update(id, formData)
    this.editing = false;
  }

  delete() {
    const id = this.route.snapshot.paramMap.get('id')
    this.artistService.delete(id)
    this.router.navigate(['/blog'])
  }

  uploadImage(event) {
    const file = event.target.files[0]
    const path = `artists/${file.name}`
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.post.image = url));
        })
      )
      .subscribe();
    }
  }
}
