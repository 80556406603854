// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDvccyJmYOTWrGxsa7_HpCk-MeEfxlX8Fo',
    authDomain: 'mekalakar-d94e6.firebaseapp.com',
    databaseURL: 'https://mekalakar-d94e6.web.app',
    projectId: 'mekalakar-d94e6',
    storageBucket: 'mekalakar-d94e6.appspot.com',
    messagingSenderId: '252855324196',
    appId: '1:252855324196:web:56851c21d56d41274c52b1',
    measurementId: 'G-GQYX4FJSCV'
  }
}
