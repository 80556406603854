import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { PostService } from '../post.service'
import { Post } from '../post'
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit {
  posts: Observable<Post[]>
  selectedIndex: number = 0;
  languages = [
    'Latest',
    'English',
    'Hindi',
    'Marathi',
    'Konkani'
  ]
  constructor(private postService: PostService, public auth: AuthService) {}

  ngOnInit() {
    this.posts = this.postService.getPosts('posts');
  }

  selectCard(index: number) {
    this.selectedIndex = index;
    this.posts = this.postService.getPosts('posts',this.languages[this.selectedIndex]);
  }

  // delete(id: string) {
  //   this.postService.delete(id)
  // }

}
