import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { SharedModule } from '../shared/shared.module'

import { ArtistDashboardComponent } from './artist-dashboard/artist-dashboard.component'
import { ArtistDetailComponent } from './artist-detail/artist-detail.component'
import { ArtistListComponent } from './artist-list/artist-list.component'
import { ArtistService } from './artist.service'

const routes: Routes = [
  { path: 'artist', component: ArtistListComponent },
  { path: 'artist/:id', component: ArtistDetailComponent },
  { path: 'artist-dashboard', component: ArtistDashboardComponent },
]

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    ArtistDashboardComponent,
    ArtistDetailComponent,
    ArtistListComponent
  ],
  providers: [ArtistService]
})
export class ArtistsModule {}
