import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

const routes: Routes = [
  { path: '', redirectTo: '/literature', pathMatch: 'full'},
  { path: '', loadChildren: './literature/literature.module#LiteratureModule' },
  { path: 'blog', redirectTo: '/blog', pathMatch: 'full'},
  { path: 'blog', loadChildren: './posts/posts.module#PostsModule' },
  { path: 'artist', redirectTo: '/artist', pathMatch: 'full'},
  { path: 'artist', loadChildren: './artists/artists.module#ArtistsModule' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
