import { Injectable } from '@angular/core'
import 'rxjs/add/operator/map';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from 'angularfire2/firestore'
import { Artist } from './artist'

@Injectable()
export class ArtistService {
  postsCollection: AngularFirestoreCollection<Artist>
  artistCollection;
  postDoc: AngularFirestoreDocument<Artist>

  constructor(private afs: AngularFirestore) {
    this.postsCollection = this.afs.collection('artists', ref =>
      ref.orderBy('published', 'desc')
    )
  }

  getPosts() {
    return this.postsCollection.snapshotChanges().map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data()
        const id = a.payload.doc.id
        return { id, ...data }
      })
    })
  }

  getArtistDataByDoc(id: string) {
    this.postDoc = this.afs.doc(`artists/${id}`)
    return this.postDoc.valueChanges()
  }

  getArtistData(id: string) {
    this.artistCollection = this.afs.collection("artists", ref => ref.where('userId', '==', id));
    return this.artistCollection.snapshotChanges().map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data()
        const id = a.payload.doc.id
        return { id, ...data }
      })
    })
    // let x = this.afs.collection("artists", ref => ref.where('userId', '==', id)).valueChanges();
// x.subscribe(users => { 

//   let test = users[0];

//   console.log( test);
//   return test;

// })
    this.postDoc = this.afs.doc(`artists/${id}`)
    return this.postDoc.valueChanges()
  }

  getArtist(id: string) {
    return this.afs.doc(`artists/${id}`)
  }

  create(data) {
    this.postsCollection.add(data)
  }

  delete(id: string) {
    return this.getArtist(id).delete()
  }

  update(id: string, formData) {
    return this.getArtist(id).update(formData)
  }
}
